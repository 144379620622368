export default [
  {
    path: "/item-type-list",
    name: "item-type-list",
    component: () => import("./ItemTypeList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/create-item-type",
    name: "create-item-type",
    component: () => import("./CreateItemType.vue"),
    meta: { requiresAuth: true },
  },
];
