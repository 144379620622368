import client from "@/client";

export default {
  async login(user) {
    try {
      const response = await client.post("auth/login", user, {
        withCredentials: true,
      });
      if (response.status !== 200 && response.data?.errors) {
        return Object.values(response.data?.errors);
      }
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      localStorage.setItem("accessToken", response?.data?.token);
    } catch (err) {
      throw new Error(err);
    }
  },

  async logout() {
    try {
      await client.post("auth/logout", {
        withCredentials: true,
      });
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");
    } catch (err) {
      console.log(err);
    }
  },
};
