export default [
  {
    path: "/",
    name: "order-list",
    component: () => import("./OrderList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/order/:mode/:orderId",
    name: "manage-order",
    component: () => import("./CreateOrder.vue"),
    meta: { requiresAuth: true },
  },
];
