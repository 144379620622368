import Vue from "vue";
import VueRouter from "vue-router";
import orderRoutes from "@/components/Orders/routes";
import partyRoutes from "@/components/Parties/routes";
import prodDetailRoutes from "@/components/ProdDetails/routes";
import itemTypeRoutes from "@/components/ItemType/routes";
import gradeRoutes from "@/components/Grade/routes";

Vue.use(VueRouter);

const routes = [
  ...orderRoutes,
  ...partyRoutes,
  ...prodDetailRoutes,
  ...itemTypeRoutes,
  ...gradeRoutes,
  {
    path: "/login",
    name: "login",
    component: () => import("@/components/Login/AppLogin.vue"),
  },
  {
    path: "/not-found",
    name: "not-found",
    component: () => import("@/components/ErrorPage.vue"),
  },
  {
    path: "*",
    redirect: {
      name: "not-found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let user = localStorage.getItem("user");
  if (!user && to.meta.requiresAuth) {
    next({ name: "login" });
  } else if (!to.meta.requiresAuth && user) {
    next({ name: "order-list" });
  } else {
    next();
  }
});

export default router;
