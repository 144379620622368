import Vue from "vue";
import router from "./router";
import store from "./store";
import AppLayout from "@/layouts/AppLayout";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

localStorage.removeItem("grades");
localStorage.removeItem("itemtypes");
localStorage.removeItem("parties");

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(AppLayout),
}).$mount("#app");
