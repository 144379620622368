export default [
  {
    path: "/grade-list",
    name: "grade-list",
    component: () => import("./GradeList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/create-grade",
    name: "create-grade",
    component: () => import("./CreateGrade.vue"),
    meta: { requiresAuth: true },
  },
];
