export default [
  {
    path: "/party-list",
    name: "party-list",
    component: () => import("./PartyList.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/manage-party/:id",
    name: "manage-party",
    component: () => import("./CreateParty.vue"),
    meta: { requiresAuth: true },
  },
];
