<template>
  <v-app>
    <v-navigation-drawer app class="blue-grey lighten-5" :mini-variant="mini">
      <template v-slot:prepend>
        <v-list-item v-if="!mini" two-line class="grey darken-2">
          <v-list-item-content>
            <v-img src="../assets/glogo.png" width="200px"></v-img>
          </v-list-item-content>
        </v-list-item>
        <v-icon
          v-else
          size="24"
          class="pa-4"
          @click="mini = false"
          color="rgba(0, 0, 0, 0.65)"
          >mdi-chevron-double-right</v-icon
        >
      </template>
      <v-divider class="mb-4"></v-divider>
      <v-list v-if="isLoggedIn()">
        <v-list-item :to="{ name: 'order-list' }">
          <v-list-item-icon>
            <v-icon color="rgba(0, 0, 0, 0.65)"
              >mdi-clipboard-list-outline</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Orders</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'prod-details' }">
          <v-list-item-icon>
            <v-icon color="rgba(0, 0, 0, 0.65)">mdi-list-status</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Production details</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'party-list' }">
          <v-list-item-icon>
            <v-icon color="rgba(0, 0, 0, 0.65)"
              >mdi-account-multiple-outline</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Parties</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'item-type-list' }">
          <v-list-item-icon>
            <v-icon color="rgba(0, 0, 0, 0.65)">mdi-shape-plus-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Item types</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'grade-list' }">
          <v-list-item-icon>
            <v-icon color="rgba(0, 0, 0, 0.65)">mdi-star-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Grades</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon color="rgba(0, 0, 0, 0.65)">mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider class="ma-2"></v-divider>
        <div v-if="!mini" class="pa-2 d-flex flex-column align-center">
          <v-btn
            fab
            x-small
            depressed
            class="grey lighten-2"
            @click="mini = true"
          >
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
          <p class="black--text">
            <strong
              >© {{ new Date().getFullYear() }}. All rights reserved</strong
            >
          </p>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container fill-height class="pa-12">
        <router-view :key="$route.path" class="router-view" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import api from "@/components/Login/api";

export default {
  name: "AppLayout",

  data: () => ({
    mini: false,
  }),

  methods: {
    isLoggedIn() {
      return localStorage.getItem("user");
    },

    async logout() {
      await api.logout();
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss">
.router-view {
  height: 100%;
  width: 100%;
}
</style>
