import axios from "axios";

const client = axios.create({
  baseURL: "https://x16bnkpp6k.execute-api.ap-south-1.amazonaws.com/prod/",
  // baseURL: "http://localhost:4001/",
  withCredentials: true,
  timeout: 5000,
});

client.interceptors.request.use(
  (req) => {
    if (req.url.includes("login")) return req;
    req.headers.Authorization = "Bearer " + localStorage.getItem("accessToken");
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

client.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.config.url.includes("login")) return err.response;
    if (err.response.status === 401 || err.response.status === 400) {
      try {
        const response = await axios.post(
          "https://x16bnkpp6k.execute-api.ap-south-1.amazonaws.com/prod/auth/token",
          // "http://localhost:4001/auth/token",
          {},
          { withCredentials: true }
        );
        if (response.status === 200) {
          localStorage.setItem("accessToken", response.data.token);
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("accessToken");
          return client(err.config);
        }
      } catch (error) {
        return Promise.reject(err);
      }
    }
    return err.response;
  }
);

export default client;
